function getHost(){
	return '';
}

function createTagElement(tag, url){
	if(tag.length && url.length){
		var tagElement = document.createElement(tag);
		var head = document.getElementsByTagName("head")[0];

		switch(tag){
			case 'link':
				tagElement.href = url;
				tagElement.rel = "stylesheet"
				break;
			case 'script':
				tagElement.src = url;
				tagElement.type = "text/javascript";
				break;
			default:
				console.log("Element did not create");
			break;
		}
		head.appendChild(tagElement);
	}else{
		console.log("Element did not create");
	}
}